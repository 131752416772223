import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DA_TASK_FREQUENCY, TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import ImageLinks from 'utils/ImageLinks';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import './ViewDailyAgenda.scss';
import { useNavigate } from 'react-router-dom';
import { selectUser } from 'store/selectors/user.selectors';
import { canUserAccessEditDA } from 'utils/shared/canUserAccessFeature';

import { useDispatch, useSelector } from 'react-redux';
import { setBusinessUnit } from 'store/actions/globalFilters.actions';
import { selectSelectedBusinessUnit, selectUserAccesses } from 'store/selectors/globalFilters.selectors';
import axios from 'axios';
import { capitalizeFirst } from 'utils/stringHelpers';

const ViewDailyAgenda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDetails = useSelector(selectUser);
  const dispatch = useDispatch();

  const userAccesses = useSelector(selectUserAccesses);
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit);
  const [selectedBu, setSelectedBu] = useState('');
  const [isBuDropDownOpen, setIsBuDropDownOpen] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState(null);

  const onPressEditChanges = () => {
    navigate('/editDailyAgenda');
  };

  const handleBuChange = ({ key }: { key: any }) => {
    const bu = userAccesses.find(
      (item: { slug: any }) => item.slug === key,
    ) || selectedBusinessUnit;
    setSelectedBu(bu);
    setIsBuDropDownOpen(false);
    dispatch(setBusinessUnit(bu));
  };

  useEffect(() => {
    const bu = selectedBusinessUnit.id ? selectedBusinessUnit : userAccesses[0].id ? userAccesses[0] : '';
    setSelectedBu(bu);
    dispatch(setBusinessUnit(bu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setScheduleData(null);
    const fetchSchedule = async () => {
      try {
        const url = `/api/v1/da-position-schedule?buId=${selectedBusinessUnit.id}`;
        const res = await axios.get(url);
        setScheduleData(res.data);
      } catch (error) {
        console.error('Error fetching operator positions:', error);
      }
    };
    fetchSchedule();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBu]);

  const onPressBack = () => {
    navigate('/dailyAgenda', { replace: true });
  };

  const transformScheduleData = (schedule: any) => {
    if (!schedule) return [];
    return schedule.positionTasks.map((positionTask: any) => {
      const activitiesByFrequency = positionTask.tasks.reduce((acc, task) => {
        const frequency = task.taskFrequency.toLowerCase();
        if (!acc[frequency]) {
          acc[frequency] = [];
        }
        acc[frequency].push(task.taskName);
        return acc;
      }, {});

      const allActivities = Object.keys(activitiesByFrequency).map((frequency: DA_TASK_FREQUENCY) => ({
        frequency: t(frequency?.toUpperCase()),
        activities: activitiesByFrequency[frequency],
      }));

      return {
        position: positionTask.positionName,
        allActivities,
      };
    });
  };

  const activeTransformedScheduleData = useMemo(() => {
    return transformScheduleData(scheduleData?.activeSchedule?.content);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  const inactiveTransformedScheduleData = useMemo(() => {
    return transformScheduleData(scheduleData?.inactiveSchedule?.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  const compareSchedules = (activeSchedule, inactiveSchedule) => {

    const allPositions = new Set([
      ...activeSchedule.map(item => item.position),
      ...inactiveSchedule.map(item => item.position),
    ]);

    const result = Array.from(allPositions).map(position => {

      const activeItem = activeSchedule.find(item => item.position === position) || { allActivities: [] };
      const inactiveItem = inactiveSchedule.find(item => item.position === position) || { allActivities: [] };

      const allFrequencies = new Set([
        ...activeItem.allActivities?.map(act => act.frequency) || [],
        ...inactiveItem.allActivities?.map(act => act.frequency) || [],
      ]);

      const allActivities = Array.from(allFrequencies).map(frequency => {
        const activeActivities =
          activeItem.allActivities.find(act => act.frequency === frequency)?.activities || [];
        const inactiveActivities =
          inactiveItem.allActivities.find(act => act.frequency === frequency)?.activities || [];

        const activities = activeActivities.map(activity => {
          if (inactiveActivities.includes(activity)) {
            return { name: activity, status: 'normal' };
          }
          return { name: activity, status: 'crossed' };
        });

        const additionalInactiveActivities = inactiveActivities
          .filter(inactActivity => !activeActivities.includes(inactActivity))
          .map(inactActivity => ({ name: inactActivity, status: 'green' }));

        return { frequency, activities: [...activities, ...additionalInactiveActivities] };
      });

      return { position, allActivities };
    });

    return result;
  };

  const comparedScheduleData = useMemo(() => {
    return compareSchedules(activeTransformedScheduleData, inactiveTransformedScheduleData);
  }, [activeTransformedScheduleData, inactiveTransformedScheduleData]);

  return (
    <div className="viewDailyAgenda">
      <div className="viewDailyAgenda__header">
        <div className="viewDailyAgenda__header__info">
          <div role='button' onClick={onPressBack} className="viewDailyAgenda__header__info__goBack">
            <img
              src={ImageLinks.arrowLeftV1}
              alt="back"
            />
          </div>
          <div className="viewDailyAgenda__header__info__content">
            <p className="viewDailyAgenda__header__info__content__title">{t(TRANSLATIONS.PROGRAMMING_AGENDA)}</p>
            <div className="viewDailyAgenda__header__info__content__buSelectorWrap">
              <p className="viewDailyAgenda__header__info__content__buSelectorWrap__label">{t(TRANSLATIONS.WATCHING_PROGRAMMING_OF)}</p>
              <div className="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown">
                <ChartDropdown
                  onChange={handleBuChange}
                  selectedDropDownItem={selectedBu}
                  dropDownItems={userAccesses}
                  dropDownOpen={isBuDropDownOpen}
                  setDropDownOpen={setIsBuDropDownOpen}
                  labelClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__label"
                  dropdownLabelClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__dropdownLabel"
                  customClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__container"
                  dropdownArrowColor="#4472C4"
                  iconClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__icon"
                />
              </div>
            </div>
          </div>
        </div>
        { canUserAccessEditDA(userDetails?.get('features')) && !!userDetails?.get('hasUserManagementAccess') &&
          <BasicButton
            text={t(TRANSLATIONS.EDIT_SCHEDULE)}
            className="viewDailyAgenda__header__edit_button"
            onClick={onPressEditChanges}
          />
        }
      </div>
      {activeTransformedScheduleData?.length === 0 && (
        <div className="viewDailyAgenda__noDataWrap">
          <img
            src={ImageLinks.teamwork}
            className="viewDailyAgenda__noDataWrap__icon"
            alt="back"
          />
          <p className="viewDailyAgenda__noDataWrap__title">{t(TRANSLATIONS.THERE_IS_NO_SCHEDULE_YET_TO_START_PRESS_EDIT_SCHEDULE)}</p>
          <p className="viewDailyAgenda__noDataWrap__subtitle">{t(TRANSLATIONS.THERE_ARE_N0_SCHEDULES_RECORDED_FOR_THE_SELECTED_BU_YET_START_BY_PRESSING_THE_EDIT_SCHEDULE_BUTTON_AT_THE_TOP_RIGHT_TO_BEGIN_ADDING_TASKS_TO_THE_DIFFERENT_POSITIONS)}</p>
        </div>
      )}
      {!!activeTransformedScheduleData?.length && (
        <div className="viewDailyAgenda__contentWrap">
          <p className="viewDailyAgenda__contentWrap__effectiveDateLabel">{`${t(TRANSLATIONS.PROGRAMMING_EFFECTIVE_FROM)} 01 de Noviembre de 2024.`}</p>
          <div className="viewDailyAgenda__contentWrap__activitiesWrap">
            <div className="viewDailyAgenda__contentWrap__activitiesWrap__content">
              {activeTransformedScheduleData?.map(i => (
                <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap">
                  <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__positionWrap">
                    <p className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__positionWrap__position">{capitalizeFirst(i?.position)}</p>
                  </div>
                  <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities">
                    <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__titleWrap">
                      <p className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__titleWrap__title">Actividades</p>
                    </div>
                    <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap">
                      {i?.allActivities?.map(a => (
                        <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap">
                          <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__list">
                            {a?.activities?.map((j, jIndex) => (
                              <p className={`viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__list__title ${jIndex === 0 ? 'viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__list__noMarginTop' : ''}`}>{capitalizeFirst(j)}</p>
                            ))}
                          </div>
                          <p className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__frequencyTitle">{a?.frequency}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent">
              <p className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__heading">{`${t(TRANSLATIONS.THESE_CHANGES_WILL_TAKE_EFFECT_UNTIL)} 01 de Diciembre de 2024.`}</p>
              {scheduleData?.inactiveSchedule && comparedScheduleData?.map(i => (
                <div className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap">
                  <div className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap__positionWrap">
                    <p className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap__positionWrap__position">{capitalizeFirst(i?.position)}</p>
                  </div>
                  <div className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap__activities">
                    <div className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap__activities__titleWrap">
                      <p className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap__activities__titleWrap__title">Actividades</p>
                    </div>
                    <div className="viewDailyAgenda__contentWrap__activitiesWrap__updatedContent__activityWrap__activities__wrap">
                      {i?.allActivities?.map(a => (
                        <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap">
                          <div className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__list">
                            {a.activities.map((activity, jIndex) => (
                              <p key={jIndex}
                                className={`viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__list__title ${activity.status === 'crossed' ? 'crossedClass' : ''} ${activity.status === 'green' ? 'greenClass' : ''}`}>
                                {capitalizeFirst(activity.name)}
                              </p>
                            ))}
                          </div>
                          <p className="viewDailyAgenda__contentWrap__activitiesWrap__content__activityWrap__activities__wrap__itemWrap__frequencyTitle">
                            {a.frequency}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewDailyAgenda;
