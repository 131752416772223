import 'features/internal-users/user/Mfa/MfaVerification.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import request from 'utils/request';
import { isUserAuthenticated } from 'store/actions/user.actions';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FETCH_STATUS, TRANSLATIONS } from 'types/enums';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import LoadingSpinner from 'components/LoadingSpinner';
import BasicButton from 'components/Button/BasicButton';

function MfaVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const userData = location.state;
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState('');
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.NO_ACTIVE_REQUEST);
  const errorMsj = fetchStatus === FETCH_STATUS.FAIL && ((errors === 'expired/invalid'
    ? t(TRANSLATIONS.CODE_HAS_EXPIRED_OR_IS_INVALID)
    : errors === 'attempts reached'
      ? t(TRANSLATIONS.YOU_HAVE_REACHED_THE_MAXIMUM_ATTEMPTS_YOUR_ACCOUNT_WILL_BE_BLOCKED)
      : errors === 'expired'
        ? t(TRANSLATIONS.YOU_NEED_TO_UPDATE_YOUR_PASSWORD_YOU_WILL_RECEIVE_AN_EMAIL_TO_CHANGE_IT)
        : t(TRANSLATIONS.ERROR)) || 'Error');

  const data = {
    email: userData?.email,
    code: code,
  };

  useEffect(() => {
    if (!location?.state?.email) {
      navigate('/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (errors === 'expired') {
        navigate('/login');
      }
    }, 10000);
    clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleVerify = async () => {
    try {
      setFetchStatus(FETCH_STATUS.PENDING);
      const url = '/api/v1/users/validateMFACode';
      await request.post(url, data);
      await request.post('/api/v1/users/login', userData);
      await dispatch(isUserAuthenticated());
      navigate('/');
      setFetchStatus(FETCH_STATUS.SUCCESS);
    } catch (error) {
      setFetchStatus(FETCH_STATUS.FAIL);
      setErrors(error.message === 'The code has expired or is invalid' || error.message === 'Incorrect code'
        ? 'expired/invalid' : error.message === 'Max Attempts'
          ? 'attempts reached' : error.message === 'Password Expired' ? 'expired' : 'network');
    }
  };

  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 card main-card-container">
                 <h2>{t(TRANSLATIONS.VERIFY_YOUR_IDENTITY)}</h2>
                 <p>
                     {t(TRANSLATIONS.YOU_ARE_TRYING_TO_LOG_IN_TO_SAFETY360_TO_MAKE_SURE_YOUR_SAFETY360_ACCOUNT_IS_SECURE_WE_HAVE_TO_VERIFY_YOUR_IDENTITY)}
                 </p>
                 <p>
                     {t(TRANSLATIONS.PLEASE_TYPE_THE_CODE_THAT_HAS_BEEN_SENT_TO_THE_EMAIL_YOU_USED_TO_LOG_IN)}
                 </p>
                 <form className="verification-form">
                     <h4>{t(TRANSLATIONS.VERIFICATION_CODE)}</h4>
                     <InputWithLabel
                         type="text"
                         value={code}
                         onChange={(e: any) => setCode(e.target.value)}
                         placeholder={t(TRANSLATIONS.ENTER_CODE_RECEIVED_VIA_EMAIL)}
                         error={errorMsj}
                     />
                     {
                         fetchStatus === FETCH_STATUS.PENDING ? (
                             <LoadingSpinner text={t(TRANSLATIONS.VERIFYING)} className="login-spinner" />
                         ) : (
                         <>
                             <BasicButton
                                 text={t(TRANSLATIONS.VERIFY)}
                                 className="main-action"
                                 onClick={handleVerify}
                                 disabled={errors === 'attempts reached' || errors === 'expired'}
                             />
                             <p className="go-back-to-login-text">
                             <Link
                                 to="/login"
                                 style={{ textDecoration: 'underline' }}
                             >
                                 {t(TRANSLATIONS.GO_BACK_TO_LOGIN)}
                             </Link>
                             </p>
                         </>
                         )
                     }
                 </form>
          </div>
        </div>
        <div className="row">
          <div className="footer">
            <p className="footer-text"> powered by ARVOLUTION </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MfaVerification;
