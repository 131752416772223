/* eslint-disable @typescript-eslint/no-redeclare */
import LibraryAxios, { AxiosInstance } from 'axios';
import { isProd } from '../constants';

class Request {
  APIUrl: string;

  axios: AxiosInstance;

  urlsRequested: string[];

  DEBOUNCE_MS = 200;

  constructor(APIUrl = '') {
    this.APIUrl = APIUrl;
    this.axios = LibraryAxios.create({
      baseURL: APIUrl,
      validateStatus: () => true,
    });
    this.urlsRequested = [];
  }

  async sendRequest(
    url: string,
    body: object,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    params: object,
    configs: object = {},
  ) {
    try {
      await this.debounce(url);

      // logging request
      let requestConfig;
      if (!isProd) {
        // to log request
        this.axios.interceptors.request.use((config: any) => {
          // assign to variable and log variable below
          if (config.url === url) {
            requestConfig = { ...config, date: new Date().toUTCString() };
          }
          return config;
        }, (error: any) => Promise.reject(error)); // Do something with request error
      }

      this.axios.interceptors.request.use(config => (
        { ...config, ...configs }),
      (error: any) => Promise.reject(error));

      const response = await this.axios({
        method, url, data: body, params,
      });
      if (response.status === 401) {
        if (url !== '/api/v1/users' && url !== '/api/v1/users/login' && url !== '/api/v1/users/validateLogIn') {
          window.location.replace('/logout');
        }
        throw new Error(response.data.message);
      }
      if (response.status !== 200 && response.status !== 201) {
        throw new Error(response.data.message);
      }
      if (!isProd) {
        // timeout for dev mode to simulate delayed RESPONSES in production
        await Request.delay();

        console.groupCollapsed(`request to: ${url}`);
        console.log('Request status: ', response.status);

        console.groupCollapsed('Request Config');
        console.log('Config: ', requestConfig);
        console.groupEnd();

        console.groupCollapsed('Response');
        console.log('Response: ', response);
        console.groupEnd();

        console.groupCollapsed('Response.data');
        console.log('', response.data);
        console.groupEnd();
        console.groupEnd();
      }
      return response.data;
    } catch (e) {
      // @ts-ignore
      switch (e.name) {
        case 'DEBOUNCED_REQUEST_ERROR':
          break;
        default:
          console.log('request error: ', e);
      }
      // always throw back the error (insure requesters all fall inside try/catch blocks!)
      throw e;
    }
  }

  async get(url: string, queryParams: object = {}) {
    return this.sendRequest(url, {}, 'GET', queryParams);
  }

  async post(url: string, body: object, queryParams: object = {}) {
    return this.sendRequest(url, body, 'POST', queryParams);
  }

  async patch(url: string, body: object = {}, queryParams: object = {}) {
    return this.sendRequest(url, body, 'PATCH', queryParams);
  }

  async put(url: string, body: object, queryParams: object = {}) {
    return this.sendRequest(url, body, 'PUT', queryParams);
  }

  async delete(url: string, bodyParams: object = {}, queryParams: object = {}) {
    return this.sendRequest(url, bodyParams, 'DELETE', queryParams);
  }

  async upload(url: string, body: object, queryParams: object = {}, config = {}) {
    return this.sendRequest(url, body, 'POST', queryParams, config);
  }

  static async delay() {
    return new Promise<any>(resolve => {
      const timeOutMs = 100 + Math.round(Math.random() * 1000);
      setTimeout(() => {
        resolve(true);
      }, timeOutMs);
    });
  }

  async debounce(url: string) {
    const nextUrlIndex = this.urlsRequested.push(url);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        for (let i = nextUrlIndex; i < this.urlsRequested.length; i++) {
          if (this.urlsRequested[i] === url) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              name: 'DEBOUNCED_REQUEST_ERROR',
              message: `Multiple Requests To Same Url Debounced. Sent Most Recent: ${url}`,
            });
          }
        }
        resolve(true);
      }, this.DEBOUNCE_MS);
    });
  }
}

const request = new Request();

export default request;
