import React, { useEffect, useReducer, useState } from 'react';
import downloadIconBlack from 'assets/icons/download-black.svg';
import ImageLinks from 'utils/ImageLinks';
import { CircularProgress, Snackbar, Checkbox, CheckboxProps } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { withStyles } from '@mui/styles';
import MuiAlert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Action_Log_Origin,
  Action_Log_Status,
  Query,
  useActionLogStatusMutation,
  useAllActionLogForLocationLazyQuery,
} from 'generated/graphql';
import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTimeFilter } from 'store/selectors/timeFilter.selector';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import SnackBarNotification, {
  AlertTypeProps,
  SnackBarNotificationProps,
} from 'components/Notification/SnackBarNotification/SnackBarNotification';
import SummaryModal from '../SummaryModal';
import ActionLogCard from '../ActionLogCard';
import { downloadActionLogsReport, trelloBoardColumns } from '../actionLogshelper';
import { Responsible } from '../createEditActionLog/createEditActionLogModal.state';
import KPIsListing from '../KPIsListing';
import RoutineListing from '../RoutinesListing';
import UsersListing from '../UsersListing';
import { Routine } from '../RoutinesListing/RoutinesListing';
import { KPI } from '../KPIsListing/KPIsListing';
import { SearchedUser } from '../UsersListing/UserListing';
import DueDatePicker from '../DueDatePicker';
import {
  ActionLogFilterStates,
  initialActionLogFiltersState,
  reducerActionLogFilters,
} from '../actionLogFilters/actionLogFilters.state';

import './TrelloBoard.scss';

const DragDropContextRN: any = DragDropContext;
const DroppableRN: any = Droppable;

type Props = {
  locationSlug: string;
  locationName: string;
  handleMonthChange: Function;
  currentMonth: string;
};

function TrelloBoard(props: Props) {
  const closedNotificationInitialState = {
    isOpen: false,
    alertTitle: '',
    alertDescription: '',
    alertType: AlertTypeProps.SUCCESS,
  };
  const OrangeCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#EFBE3F',
      },
    },
    checked: {},
  })((MUIRadioProps: CheckboxProps) => <Checkbox color="default" {...MUIRadioProps} />);
  const { t } = useTranslation();
  const { locationSlug, locationName, handleMonthChange, currentMonth } = props;
  const timeFilter = useSelector(selectTimeFilter);

  const [getActionLogs, actionLogs] = useAllActionLogForLocationLazyQuery();
  const [setActionLogStatus] = useActionLogStatusMutation();
  const [dndActionLogs, setDndActionLogs] = useState<Query['groupActionLogsInDCByStatus']>({
    [Action_Log_Status.Open]: [],
    [Action_Log_Status.InProgress]: [],
    [Action_Log_Status.ForRevision]: [],
    [Action_Log_Status.Completed]: [],
  });
  const [actionLogId, setActionLogId] = useState<string | undefined>(undefined);
  const [
    summaryModalStatus,
    setSummaryModalStatus,
  ] = useState<ACTION_LOG_SUMMARY_MODAL_STATUS | undefined>();
  const [selectedColumnStatus, setSelectedColumnStatus] = useState<Action_Log_Status | undefined>();
  const [notificationDetails, setNotificationDetails] = useState<Omit<SnackBarNotificationProps, 'closeNotification'>>(closedNotificationInitialState);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState({
    count: 0,
    value: false,
  });
  const [actionLogFilters, dispatchActionLogFilters] = useReducer(
    reducerActionLogFilters,
    initialActionLogFiltersState,
  );

  function handleExcelDownloadButtonClicked() {
    downloadActionLogsReport(FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER, timeFilter, locationSlug);
  }

  // filter functions
  function handleViewDelayTask(event: any) {
    dispatchActionLogFilters({
      type: ActionLogFilterStates.DELAY_TASK,
      payload: event.target.checked,
    });
  }

  function handleSelectDueDate(date: Date) {
    dispatchActionLogFilters({ type: ActionLogFilterStates.DUE_DATE, payload: date });
  }

  function handleSelectRoutine(routine: Routine) {
    dispatchActionLogFilters({ type: ActionLogFilterStates.ROUTINE, payload: routine });
  }

  function handleSelectKPIs(KPIs: KPI[]) {
    dispatchActionLogFilters({ type: ActionLogFilterStates.KPIS, payload: KPIs });
  }

  function handleSelectSearchedUser(searchedUsers: SearchedUser[]) {
    dispatchActionLogFilters({
      type: ActionLogFilterStates.SEARCHED_USERS,
      payload: searchedUsers,
    });
  }
  const columnData = trelloBoardColumns(t);

  function resetFilters() {
    dispatchActionLogFilters({ type: 'RESET', payload: undefined });
  }
  function closeNotification() {
    setNotificationDetails(closedNotificationInitialState);
  }

  async function updateActionLogColumns() {
    try {
      if (actionLogs.refetch) {
        await actionLogs.refetch();
      }
    } catch (error) {
      console.log('updateActionLogColumns e()', error);
    }
  }

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId) return;


    const sourceColumn = source.droppableId as Action_Log_Status;

    const destinationColumn = destination.droppableId as Action_Log_Status;
    const movingCard = dndActionLogs[sourceColumn]?.find((_, index) => index === source.index);
    setDndActionLogs(preState => ({
      ...preState,
      [sourceColumn]: [...preState[sourceColumn].filter((_, index) => index !== source.index)],
      [destinationColumn]: preState && [{ ...movingCard }, ...preState[destinationColumn]],
    }));
    try {
      await setActionLogStatus({
        variables: {
          actionLogId: Number(movingCard?.id),

          status: destination.droppableId as Action_Log_Status,
          isOwnerCheckSurpassed: true,
        },
      });
    } catch (error) {
      console.log('e onDragEnd()', error);
    } finally {
      updateActionLogColumns();
    }
  }

  function handleAlertClose() {
    setShowAlert(false);
  }

  function handleActionLogCardClick(id: string | undefined, columnStatus: Action_Log_Status) {
    if (id) {
      setSummaryModalStatus(ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY);
      setSelectedColumnStatus(columnStatus);
    } else {
      setSummaryModalStatus(undefined);
    }
    setActionLogId(id);
  }

  function initiateAddNewActionLog(status: Action_Log_Status) {
    setSummaryModalStatus(ACTION_LOG_SUMMARY_MODAL_STATUS.CREATE);
    setSelectedColumnStatus(status);
  }

  function handleCloseSummaryModal(isFromEdit?: boolean) {
    console.log({ isFromEdit });
    if (!isFromEdit) {
      setActionLogId(undefined);
      setSummaryModalStatus(undefined);
      setSelectedColumnStatus(undefined);
      updateActionLogColumns();
    } else {
      setSummaryModalStatus(ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY);
    }
  }

  useEffect(() => {
    if (!actionLogId) {
      updateActionLogColumns();
    }
    // eslint-disable-next-line
  }, [actionLogId]);

  useEffect(() => {
    const involvedUsers = actionLogFilters[ActionLogFilterStates.SEARCHED_USERS].length
      ? actionLogFilters[ActionLogFilterStates.SEARCHED_USERS].map(
        (involvedUser: SearchedUser) => involvedUser.value,
      )
      : undefined;
    const KPIs = actionLogFilters[ActionLogFilterStates.KPIS].length
      ? actionLogFilters[ActionLogFilterStates.KPIS].map((KPIInFilter: KPI) => KPIInFilter.value)
      : undefined;
    const meeting = actionLogFilters[ActionLogFilterStates.ROUTINE]?.value
      ? actionLogFilters[ActionLogFilterStates.ROUTINE]?.value
      : undefined;
    const dueDate = actionLogFilters[ActionLogFilterStates.DUE_DATE]
      ? new Date(actionLogFilters[ActionLogFilterStates.DUE_DATE].getTime() + 24 * 3600 * 1000)
      : undefined;
    const isDelayed = actionLogFilters[ActionLogFilterStates.DELAY_TASK]
      ? actionLogFilters[ActionLogFilterStates.DELAY_TASK]
      : false;
    getActionLogs({
      variables: {
        actionLogsLocationSlug: locationSlug,
        startTime: timeFilter.get('startTime'),
        endTime: timeFilter.get('endTime'),
        filters: {
          meeting,
          KPIs,
          involvedUsers,
          dueDate,
          delayed: {
            isDelayed,
            currentDate: new Date(),
          },
        },
      },
    });
  }, [
    locationSlug,
    actionLogFilters,
    getActionLogs,
    timeFilter,
  ]);

  useEffect(() => {
    if (!actionLogs.loading) {
      setLoading(prevLoading => ({
        count: prevLoading.count,
        value: false,
      }));
    } else if (actionLogs.loading && loading.count === 0) {
      setLoading({
        count: 1,
        value: true,
      });
    }
  }, [actionLogs.loading, loading.count]);

  useEffect(() => {
    if (actionLogs?.data?.groupActionLogsInDCByStatus) {
      setDndActionLogs(
        actionLogs?.data?.groupActionLogsInDCByStatus as Query['groupActionLogsInDCByStatus'],
      );
    }
    // eslint-disable-next-line
  }, [actionLogs?.data?.groupActionLogsInDCByStatus]);

  return (
    <DragDropContextRN onDragEnd={onDragEnd}>
      <section className="trello_container">
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MuiAlert onClose={handleAlertClose} severity="error" elevation={6} variant="filled">
            <AlertTitle>Failed</AlertTitle>
            You are not the owner of this action log.
          </MuiAlert>
        </Snackbar>
        <SnackBarNotification {...{ ...notificationDetails, closeNotification }} />
        <div className="header">
          <div>
            <h2 className="title">{locationName}</h2>
            <p className="subtitle">
              {t(TRANSLATIONS.ACTION_LOG_TABLE_FOR_LOCATION_NAME, { locationName })}
            </p>
          </div>
          <div className="header__right">
            <div className="chechbox-container">
              <OrangeCheckbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                onChange={e => handleViewDelayTask(e)}
                checked={actionLogFilters[ActionLogFilterStates.DELAY_TASK]}
              />
              <p className="styleText">{t(TRANSLATIONS.DELAY_TASK)}</p>
            </div>

            <div
              className={`month-selector active ${actionLogFilters[
                ActionLogFilterStates.DUE_DATE
              ] && 'filter-added'}`}
            >
              <DueDatePicker
                selectedDueDate={actionLogFilters[ActionLogFilterStates.DUE_DATE]}
                handleSelectDueDate={handleSelectDueDate}
              />
            </div>
            <div
              className={`month-selector active ${actionLogFilters[ActionLogFilterStates.ROUTINE]
                ?.label && 'filter-added'}`}
            >
              <RoutineListing
                selectedRoutine={actionLogFilters[ActionLogFilterStates.ROUTINE]}
                setSelectedRoutine={handleSelectRoutine}
              />
            </div>
            <div
              className={`month-selector active ${actionLogFilters[ActionLogFilterStates.KPIS]
                .length && 'filter-added'}`}
            >
              <KPIsListing
                selectedKPIs={actionLogFilters[ActionLogFilterStates.KPIS]}
                setSelectedKPIs={handleSelectKPIs}
              />
            </div>
            <div
              className={`month-selector active ${actionLogFilters[
                ActionLogFilterStates.SEARCHED_USERS
              ].length && 'filter-added'}`}
            >
              <UsersListing
                locationSlug={locationSlug}
                selectedUsers={actionLogFilters[ActionLogFilterStates.SEARCHED_USERS]}
                setSelectedUsers={handleSelectSearchedUser}
              />
            </div>
            <div
              className={`month-selector${
                !(
                  actionLogFilters[ActionLogFilterStates.ROUTINE]?.label
                  || actionLogFilters[ActionLogFilterStates.SEARCHED_USERS].length
                  || actionLogFilters[ActionLogFilterStates.KPIS].length
                  || actionLogFilters[ActionLogFilterStates.DUE_DATE]
                )
                  ? ' inactive'
                  : ' active'
              }`}
              role="button"
              onClick={resetFilters}
            >
              <p>{t(TRANSLATIONS.DELETE)}</p>
            </div>
            <div className="month-selector">
              <MonthlyTimeFilter
                onChange={handleMonthChange}
                currentValue={currentMonth}
                t={t}
                bgColor="transparent"
              />
            </div>

            <div className="download_btn" role="button" onClick={handleExcelDownloadButtonClicked}>
              {t(TRANSLATIONS.DOWNLOAD)}
              <img src={downloadIconBlack} alt="download" />
            </div>
          </div>
        </div>
        <div className="different_board_column">
          {loading.value ? (
            <div className="loader_for_action_log">
              <CircularProgress />
            </div>
          ) : (
            columnData.map(column => (
              <div className="single_column" key={column.name}>
                <div className="single_column__header mb-3">
                  <img className="single_column__header__image" src={column.icon} alt="watching" />
                  <p className="single_column__header__title">{column.name}</p>
                </div>
                <div className="single_column__vertical_scroll">
                  <DroppableRN droppableId={column.status}>
                    {provided => (
                      <div className="single_column__list_container" ref={provided.innerRef}>
                        <div
                          className="create_action_log_container"
                          role="presentation"
                          onClick={() => initiateAddNewActionLog(column.status)}
                        >
                          <img
                            className="create_action_log_image"
                            src={ImageLinks.greenPlus}
                            alt="create_action_log"
                          />
                          <p className="create_action_log_text"> {t(TRANSLATIONS.ADD_ACTION_LOG)}</p>
                        </div>
                        {dndActionLogs[column.status].length ? (
                          dndActionLogs[column.status].map(
                            (actionLog, index) => !!actionLog && (
                              <ActionLogCard
                                key={actionLog.id}
                                id={actionLog.id}
                                pillar={actionLog.pillar}
                                owner={actionLog.owner}
                                description={actionLog.description}
                                originText={actionLog.originText || ''}

                                origin={actionLog.origin as Action_Log_Origin}
                                endDate={actionLog.endDate}
                                responsibles={
                                  (actionLog.responsibles as unknown) as Responsible[]
                                }
                                evidence={actionLog?.images?.length}
                                folioNumber={actionLog?.folioNumber}
                                onClick={handleActionLogCardClick}
                                index={index}
                                numberOfComments={actionLog?.numberOfComments || 0}
                                columnStatus={column.status}
                              />
                            ),
                          )
                        ) : (
                          <div className="no_card_container">
                            <img
                              className="no_card_container__image"
                              src={ImageLinks.summary}
                              alt="empty"
                            />
                            <p className="no_card_container__text">
                              {t(TRANSLATIONS.THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET)}
                            </p>
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </DroppableRN>
                </div>
              </div>
            ))
          )}
        </div>
        {summaryModalStatus && (
          <SummaryModal
            locationSlug={locationSlug}
            locationName={locationName}
            actionLogId={actionLogId}
            summaryModalStatus={summaryModalStatus}
            setNotificationDetails={setNotificationDetails}
            createActionLogInitialStatus={selectedColumnStatus}
            handleCloseSummaryModal={handleCloseSummaryModal}
            setSummaryModalStatus={setSummaryModalStatus}
            selectedRoutine={actionLogFilters[ActionLogFilterStates.ROUTINE]}
          />
        )}
      </section>
    </DragDropContextRN>
  );
}
export default TrelloBoard;
