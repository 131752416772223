/* eslint-disable @typescript-eslint/default-param-last */
import { fromJS } from 'immutable';
import { FILTER_ACCESS_LEVELS, GLOBAL_SEARCH_TYPES } from 'types/enums';
import { BusinessUnitJSON, SearchableLocation } from 'types/interfaces';
import {
  SET_BUSINESS_UNIT,
  SET_ZONE,
  SET_ALL_ZONES,
  SET_SUBZONE,
  SET_ALL_SUBZONES,
  SET_LOCATION,
  SET_ALL_LOCATIONS,
  SET_ANALYTICS_FILTERS,
  SET_LOCATIONS,
  SET_CLUSTER,
  SET_ALL_CLUSTERS,
} from '../actions/globalFilters.actions';

const INITIAL_STATE = fromJS({});

const makeLocationSearchable = (bu: BusinessUnitJSON[]) => {
  const arr = [] as SearchableLocation[];
  bu?.forEach(b => {
    arr.push({
      type: GLOBAL_SEARCH_TYPES.LOCATION,
      subType: FILTER_ACCESS_LEVELS.BUSINESS_UNIT,
      slug: b.slug,
      name: b.name,
      id: b?.id,
    });
    b.zones.forEach(zone => {
      arr.push({
        type: GLOBAL_SEARCH_TYPES.LOCATION,
        subType: FILTER_ACCESS_LEVELS.ZONE,
        slug: zone.slug,
        name: zone.name,
        bu: {
          slug: b.slug,
          name: b.name,
        },
      });
      zone.subzones.forEach(subzone => {
        arr.push({
          type: GLOBAL_SEARCH_TYPES.LOCATION,
          subType: FILTER_ACCESS_LEVELS.SUBZONE,
          slug: subzone.slug,
          name: subzone.name,
          bu: {
            slug: b.slug,
            name: b.name,
          },
          zone: {
            slug: zone.slug,
            name: zone.name,
          },
        });
        subzone.locations.forEach((dc: any) => {
          arr.push({
            type: GLOBAL_SEARCH_TYPES.LOCATION,
            subType: FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER,
            slug: dc.slug,
            name: dc.name,
            timezoneDomain: dc?.timezoneDomain,
            bu: {
              slug: b.slug,
              name: b.name,
            },
            zone: {
              slug: zone.slug,
              name: zone.name,
            },
            subzone: {
              slug: subzone.slug,
              name: subzone.name,
            },
          });
        });
      });
    });
  });
  return arr;
};

const globalFiltersReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_BUSINESS_UNIT:
      return state.set('businessUnit', fromJS(action.businessUnit));
    case SET_ZONE:
      return state.set('zone', fromJS(action.zone));
    case SET_ALL_ZONES:
      return state.set('allZones', fromJS(action.zones));
    case SET_SUBZONE:
      return state.set('subzone', fromJS(action.subzone));
    case SET_ALL_SUBZONES:
      return state.set('allSubzones', fromJS(action.subzones));
    case SET_LOCATION:
      return state.set('location', fromJS(action.location));
    case SET_ALL_LOCATIONS:
      return state.set('allLocations', fromJS(action.locations));
    case SET_CLUSTER:
      return state.set('cluster', fromJS(action.cluster));
    case SET_ALL_CLUSTERS:
      return state.set('allClusters', fromJS(action.clusters));
    case SET_ANALYTICS_FILTERS:
      return state
        .set('analyticsFilters', fromJS(action.analyticsFilters))
        .set('searchableLocations', makeLocationSearchable(action.analyticsFilters?.businessUnits));
    case SET_LOCATIONS:
      return state.set('locationsToAccess', fromJS(action.locations));
    default:
      return state;
  }
};

export default globalFiltersReducer;
