import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_STATUS, TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { useDispatch, useSelector } from 'react-redux';
import './DailyAgendaDashboard.scss';
import CircularProgress from '@mui/material/CircularProgress';
import ImageLinks from 'utils/ImageLinks';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ExpandableTable from 'components/ExpandableTable';
import { useNavigate } from 'react-router-dom';
import { setCanAccessScheduleScreen } from 'store/actions/dailyAgenda.actions';
import { selectUser } from 'store/selectors/user.selectors';
import Header from 'components/Header';
import { selectGlobalFilters, selectSelectedBusinessUnit } from 'store/selectors/globalFilters.selectors';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import { capitalizeFirst } from 'utils/stringHelpers';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import axios from 'axios';
import { selectLocationsToAccess, selectSelectedLocation } from 'store/selectors/globalFilters.selectors';
import { useGetUserDailyAgendaProgressLazyQuery } from 'generated/graphql';
import i18next from 'i18next';
import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';

const userLanguage = i18next.language?.indexOf('en') === 0 ? 'en' : 'es';

const dailyAgendaPosition = [
  'AUDITOR DPO',
  'MANAGER DPO',
];

export enum DropDownEnums {
  month = 'month',
  year = 'year',
}

const { CancelToken } = axios;

const DailyAgenda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    months: [monthSlug],
    lastMonth: [monthSlug === 'enero' ? 'diciembre' : monthArr[monthIndex - 1]],
    monthNumber: monthIndex + 1,
  }));

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const user = useSelector((state: any) => state.get('user'))?.toJS();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState<DropDownEnums | undefined>(undefined);

  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [dropDownOpenMonth, setDropDownOpenMonth] = useState(false);

  const [callGetUserProgress, { data: UserDAProgress }] = useGetUserDailyAgendaProgressLazyQuery();
  const monthlyTasksProgressPercentage = UserDAProgress?.getUserDailyAgendaProgress?.totalScheduleMonthProgressPercentage;
  const monthlyTotalTasks = UserDAProgress?.getUserDailyAgendaProgress?.totalScheduleMonthTasks;
  const monthlyTotalTasksCompleted = UserDAProgress?.getUserDailyAgendaProgress?.totalScheduleMonthTasksCompleted;
  const quarterlyTasksProgressPercentage = UserDAProgress?.getUserDailyAgendaProgress?.quarterlyTasksProgress?.tasksProgressPercentage;
  const quarterlyTotalTasks = UserDAProgress?.getUserDailyAgendaProgress?.quarterlyTasksProgress?.totalTasks;
  const quarterlyTotalTasksCompleted = UserDAProgress?.getUserDailyAgendaProgress?.quarterlyTasksProgress?.totalTasksCompleted;

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };
  const yearOptions = getLatestYearsAndCurrentYear();
  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const locations = useSelector(selectLocationsToAccess);

  const isDCSelected = useSelector(selectSelectedLocation);
  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);

  const onPressDownload = () => {
    setShowDownloadModal(true);
  };

  const userDetails = useSelector(selectUser);

  const canAccessScheduleScreen = dailyAgendaPosition.includes(userDetails?.get('position')?.toUpperCase());

  const [DAData, setDAData] = useState();
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit);
  const globalFilters = useSelector(selectGlobalFilters);
  const [fetchStatus, setFetchStatus] = useState(undefined);

  useEffect(() => {
    setDAData(null);
    const fetchData = async () => {
      try {
        setFetchStatus(FETCH_STATUS.PENDING);
        const url = '/api/v1/da-personal-tasks';
        const body = {
          level: 'bu',
          id: selectedBusinessUnit?.id || 1,
        };
        const res = await request.post(url, body);
        setDAData(res.response);
        setFetchStatus(FETCH_STATUS.SUCCESS);
      } catch (error) {
        setFetchStatus(FETCH_STATUS.FAIL);
        console.error('Error fetching operator positions:', error);
      }
    };
    if (selectedBusinessUnit?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessUnit]);

  useEffect(() => {
    callGetUserProgress();
    // eslint-disable-next-line
  }, []);

  // const getLatestYearsAndCurrentYear = () => {
  //   const localDate: Date = new Date();
  //   const baseYear: number = 2021;
  //   const currentYear: number = localDate.getUTCFullYear();
  //   const years: any[] = [];

  //   for (let i = baseYear; i <= currentYear; i++) {
  //     const currentYearToString: string = i.toString();

  //     years.push({
  //       label: currentYearToString,
  //       slug: currentYearToString,
  //       year: i,
  //     });
  //   }

  //   return years.reverse();
  // };
  // const yearOptions = getLatestYearsAndCurrentYear();
  // const getCurrentYear = () => {
  //   const localDate: Date = new Date();
  //   const currentYear: number = localDate.getUTCFullYear();
  //   const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
  //   return yearDataToReturn ?? yearOptions[0];
  // };
  // const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  // const [loading, setLoading] = useState<boolean>(false);

  const onPressSchedule = () => {
    dispatch(setCanAccessScheduleScreen(true));
    navigate('/viewDailyAgenda');
  };

  const rowElements = (DAMap: any) => {
    const validateWeekData = (percentageField: string, completedFields: string[], totalFields: string[]) => {
      const hasData = completedFields.some(field => DAMap[field] !== undefined && DAMap[field] !== null) ||
        totalFields.some(field => DAMap[field] !== undefined && DAMap[field] !== null);
      if (!hasData) {
        return '';
      }

      const percentage = +DAMap[percentageField]?.toFixed(2) || 0;
      const completedTasks = completedFields.map(field => DAMap[field]).find(value => value !== undefined && value !== null) || 0;
      const totalTasks = totalFields.map(field => DAMap[field]).find(value => value !== undefined && value !== null) || 0;

      return `${percentage}% ${completedTasks} / ${totalTasks}`;
    };

    const validatePercentage = (percentageFields: string[], completedFields: string[], totalFields: string[]) => {
      const hasData = percentageFields.some(field => DAMap[field] !== undefined && DAMap[field] !== null) ||
        completedFields.some(field => DAMap[field] !== undefined && DAMap[field] !== null) ||
        totalFields.some(field => DAMap[field] !== undefined && DAMap[field] !== null);
      if (!hasData) {
        return '';
      }

      const percentage = percentageFields?.map(field => DAMap[field])?.find(value => value !== undefined && value !== null)?.toFixed(2) || '0';
      const completedTasks = completedFields.map(field => DAMap[field]).find(value => value !== undefined && value !== null) || 0;
      const totalTasks = totalFields.map(field => DAMap[field]).find(value => value !== undefined && value !== null) || 0;

      return `${+percentage}% ${completedTasks} / ${totalTasks}`;
    };

    return {
      activities: DAMap.name || DAMap.userName || DAMap.taskName || DAMap.taskSlug,
      frequency: DAMap.frequency || DAMap.taskFrequency,
      week1: validateWeekData(
        'week0Percentage',
        ['totalWeek0CompletedTasks', 'totalTasksCompletedWeek0', 'week0CompletedTaskCount', 'week0TaskCompletedCount'],
        ['totalWeek0Tasks', 'totalTasksWeek0', 'week0TaskCount'],
      ),
      week2: validateWeekData(
        'week1Percentage',
        ['totalWeek1CompletedTasks', 'totalTasksCompletedWeek1', 'week1CompletedTaskCount', 'week1TaskCompletedCount'],
        ['totalWeek1Tasks', 'totalTasksWeek1', 'week1TaskCount'],
      ),
      week3: validateWeekData(
        'week2Percentage',
        ['totalWeek2CompletedTasks', 'totalTasksCompletedWeek2', 'week2CompletedTaskCount', 'week2TaskCompletedCount'],
        ['totalWeek2Tasks', 'totalTasksWeek2', 'week2TaskCount'],
      ),
      week4: validateWeekData(
        'week3Percentage',
        ['totalWeek3CompletedTasks', 'totalTasksCompletedWeek3', 'week3CompletedTaskCount', 'week3TaskCompletedCount'],
        ['totalWeek3Tasks', 'totalTasksWeek3', 'week3TaskCount'],
      ),
      week5: validateWeekData(
        'week4Percentage',
        ['totalWeek4CompletedTasks', 'totalTasksCompletedWeek4', 'week4CompletedTaskCount', 'week4TaskCompletedCount'],
        ['totalWeek4Tasks', 'totalTasksWeek4', 'week4TaskCount'],
      ),
      percentage: validatePercentage(
        ['totalPercentage', 'percentage', 'tasksProgressPercentage'],
        ['totalTasksCompleted', 'totalCompletedTask', 'totalCompletedTasks'],
        ['totalTasks'],
      ),
    };
  };

  const data = useMemo(() => {
    if (!DAData) return;

    const formatPositionName = (name: string) => name.replace(/_/g, ' ');

    const mapDistributionCenters = (distributionCenters: unknown[]) =>
      distributionCenters.map((dc: any) => ({
        ...rowElements(dc),
        subRows: Object.keys(dc.positions || {}).map((positionName: string) => {
          const formattedName = formatPositionName(positionName);
          const positionsArray = Object.values(dc.positions[positionName]).flat();

          return {
            ...rowElements({ name: formattedName }),
            subRows: positionsArray
              .filter((userp: any) => userp.daPositionName === formattedName)
              .map((userp: any) => {
                const allTasks = Object.values(userp.tasksByFrequency).flatMap(tasks => tasks);
                return ({
                  ...rowElements(userp),
                  subRows: allTasks.map(task => ({
                    ...rowElements(task),
                  })),
                });
              }),
          };
        }),
      }));

    const mapSubzones = (subzones: unknown[]) =>
      subzones.map((subzone: any) => ({
        ...rowElements(subzone),
        subRows: mapDistributionCenters(subzone.distributionCenters),
      }));

    const mapZones = (zones: unknown[]) =>
      zones.map((zone: any) => ({
        ...rowElements(zone),
        subRows: mapSubzones(zone.subzones),
      }));

    return [DAData].map((bu: any) => ({
      ...rowElements(bu),
      subRows: mapZones(bu.zones),
    }));
  }, [DAData]);


  const columns = useMemo(
    () => [
      {
        Header: t(TRANSLATIONS.ACTIVITIES),
        accessor: 'activities',
        getProps: (state: any) => {
          let paddingLeft;
          if (state.row.depth === 1) paddingLeft = 20;
          if (state.row.depth === 2) paddingLeft = 30;
          if (state.row.depth === 3) paddingLeft = 40;
          if (state.row.depth === 4) paddingLeft = 50;
          if (state.row.depth === 5) paddingLeft = 50;
          if (state.row.depth === 6) paddingLeft = 50;
          if (state.row.depth) {
            return {
              style: {
                fontWeight: 'bold',
                paddingLeft: paddingLeft,
              },
            };
          }
          return {};
        },
      },
      {
        Header: '',
        accessor: 'frequency',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 1`,
        accessor: 'week1',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 2`,
        accessor: 'week2',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 3`,
        accessor: 'week3',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 4`,
        accessor: 'week4',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 5`,
        accessor: 'week5',
      },
      {
        Header: `${t(TRANSLATIONS.TOTAL_MONTH)}`,
        accessor: 'percentage',
        getProps: () => {
          const color = () => {
            // eslint-disable-next-line radix
            // const percentage = Math.round(parseInt(state.value.split('%')[0]));
            const percentage = 0;
            if (percentage <= 70) {
              return '#C00000';
            }
            if (percentage > 70 && percentage < 90) {
              return '#404040';
            }
            return '#70AD47';
          };
          return {
            style: {
              color: color(),
            },
          };
        },
      },
    ],
    [t],
  ) as any;

  const handleDropMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedMonth(selectedDropDownMonth || validMonthOptions[0]);
    setDropDownOpenMonth(false);
    setIsDropDownOpen(undefined);
  };

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) {
        handleDropMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

    // eslint-disable-next-line
  }, [validMonthOptions]);

  const ResourcesEmptyPage = () => {
    return (
      globalFilters?.size ? (
        <div className="roadSafetyResources__selectDc">
          <img src={ImageLinks.selectDc} alt="Select Location" />
          <div className="roadSafetyResources__selectDc__info">
            <p className="roadSafetyResources__selectDc__info__title">{t(TRANSLATIONS.SELECT_A_BUSINESS_UNIT)}</p>
            <p className="roadSafetyResources__selectDc__info__subTitle">{t(TRANSLATIONS.IN_ORDER_TO_ACCESS_DAILY_AGENDA_IT_IS_NECESSARY_TO_SELECT_A_BUSINESS_UNIT_USING_THE_TOP_BAR)}</p>
          </div>
        </div>
      ) : (
        <LoadingSpinner height={30} style={{ position: 'fixed', top: '50%', left: '50%' }} />
      )
    );
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const downloadDAExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const payload = {
          locations: locations?.join(','),
          userLanguage,
          year: selectedYear?.year,
          month: selectedMonth?.monthNumber - 1,
        };
        const url = '/api/v1/download-daily-agenda-excel';
        // const url = `/api/v1/da/excel?buId=${JSON.stringify([selectedBu.id])}`;
        const res = await axios({
          url,
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          data: payload,
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations, selectedMonth?.monthNumber, selectedYear?.year],
  );

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };


  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: DropDownEnums) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  return (
    <div className="dailyAgenda">
      <div className="dailyAgenda__header">
        <p className="dailyAgenda__header__title">{t(TRANSLATIONS.DAILY_AGENDA)}</p>
        <div className="dailyAgenda__header__button_container">
          <BasicButton icon={<img src={ImageLinks.downloadFolder} alt="failed request" className='dailyAgenda__header__button_container__downloadIcon' />} text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="dailyAgenda__header__button_container__download_button" onClick={onPressDownload} />
          {canAccessScheduleScreen && (
            <BasicButton text={t(TRANSLATIONS.SCHEDULE)} className="dailyAgenda__header__button_container__schedule_button" onClick={onPressSchedule} />
          )}
        </div>
      </div>
      {fetchStatus === FETCH_STATUS.PENDING ? (
        <div className="dailyAgenda__loader">
          <CircularProgress />
        </div>
      ) :
        selectedBusinessUnit?.id && data && fetchStatus === FETCH_STATUS.SUCCESS
          ? (
            <>
              <div className="dailyAgenda__userInfo">
                <div className="dailyAgenda__userInfo__profile">
                  <img
                    src={user?.picture?.length ? user.picture : encodeURI(`https://ui-avatars.com/api/?name=${user?.name}&color=ff0000`)}
                    className="dailyAgenda__userInfo__profile__userImage"
                    alt="uiAvatarImage"
                  />
                  <div className="dailyAgenda__userInfo__profile__info">
                    <p className='dailyAgenda__userInfo__profile__info__name'>{user?.name}</p>
                    <p className="dailyAgenda__userInfo__profile__info__email">{capitalizeFirst(user?.position)} </p>
                  </div>
                </div>
                <div className="dailyAgenda__userInfo__progressWrapper">
                  <div className="dailyAgenda__userInfo__progressWrapper__progress">
                    <div className="dailyAgenda__userInfo__progressWrapper__progress__indicator">
                      <CircularProgress variant="determinate" value={quarterlyTasksProgressPercentage || 0} />
                      <p className="dailyAgenda__userInfo__progressWrapper__progress__indicator__label">{+quarterlyTasksProgressPercentage?.toFixed(0) || 0}%</p>
                    </div>
                    <div className="dailyAgenda__userInfo__progressWrapper__progress__info">
                      <p className="dailyAgenda__userInfo__progressWrapper__progress__info__title">{`${quarterlyTotalTasksCompleted || 0}/${quarterlyTotalTasks || 0} ${t(TRANSLATIONS.QUARTERLY_PROGRESS)}`}</p>
                      <p className="dailyAgenda__userInfo__progressWrapper__progress__info__subtitle">{t(TRANSLATIONS.THESE_ACTIVITIES_HAVE_INDIVIDUAL_DUE_DATES)}</p>
                    </div>
                  </div>
                  <div className="dailyAgenda__userInfo__progressWrapper__progress">
                    <div className="dailyAgenda__userInfo__progressWrapper__progress__indicator">
                      <CircularProgress variant="determinate" value={monthlyTasksProgressPercentage || 0} />
                      <p className="dailyAgenda__userInfo__progressWrapper__progress__indicator__label">{+monthlyTasksProgressPercentage?.toFixed(0) || 0}%</p>
                    </div>
                    <div className="dailyAgenda__userInfo__progressWrapper__progress__info">
                      <p className="dailyAgenda__userInfo__progressWrapper__progress__info__title">{`${monthlyTotalTasksCompleted || 0}/${monthlyTotalTasks || 0} ${t(TRANSLATIONS.MONTHLY_PROGRESS)}`}</p>
                      <div className="dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap">
                        <p className="dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap__subtitle">{`${t(TRANSLATIONS.YOU_HAVE_UNTIL)} ${i18next.language === 'es' ? 'el' : ''}`}</p>
                        <p className="dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap__date dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap__capitalize">{`${format(new Date(), 'EEEE d', { locale: (i18next.language === 'es' ? es : enUS) })}`}</p>
                        {i18next.language === 'es' && (
                          <p className="dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap__date">de</p>
                        )}
                        <p className="dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap__date dailyAgenda__userInfo__progressWrapper__progress__info__subtitleWrap__capitalize">{`${format(new Date(), 'MMMM', { locale: (i18next.language === 'es' ? es : enUS) })}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dailyAgenda__disclaimer">
                <p className="dailyAgenda__disclaimer__title">{t(TRANSLATIONS.THE_QUARTERLY_ACTIVITIES_DO_NOT_COUNT_FOR_THE_MONTHLY_SUMS_OF_THE_USERS)}</p>
              </div>
              <div className="dailyAgenda__monthSelector">
                <ChartDropdown
                  onChange={handleDropMonthChange}
                  selectedDropDownItem={selectedMonth}
                  dropDownItems={monthOptions}
                  dropDownOpen={dropDownOpenMonth}
                  setDropDownOpen={setDropDownOpenMonth}
                  labelClassName="label-default"
                />
              </div>
              <ExpandableTable
                removeDepthMargin
                tableClassName="dailyAgenda__table" {...{ columns, data, isExpandable: true }}
                showExpanderAtLast
                openIcon={ImageLinks.chevronUp}
                closeIcon={ImageLinks.chevronDown3}
              />
            </>
          ) :
          <ResourcesEmptyPage />
      }
      {showDownloadModal && (
        <Modal
          modalContainerStyles={{
            width: '70vw',
            left: '20%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <div className="downloadMoal">
            <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
            {showProgressBar ? (
              <>
                <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
                {!downloadError && !sentViaEmail && (
                  <div className="downloadMoal__progress_bar">
                    <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                    <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="downloadMoal__description">{t(TRANSLATIONS.SELECT_THE_BU_AND_PERIOD_TO_DOWNLOAD_THE_DATA)}</p>
                <div className="downloadMoal__header">
                  <Header hideZone hideSubZone hideCluster hideLocation showLogo={false} showSettings={false} />
                </div>
                <div className="downloadMoal__yearFilter">
                  <div>
                    <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                    <ChartDropdown
                      onChange={handleDropYearChange}
                      selectedDropDownItem={selectedYear}
                      dropDownItems={yearOptions}
                      dropDownOpen={isDropDownOpen === DropDownEnums.year}
                      setDropDownOpen={
                        (opened: boolean) => dropDownOpenCloseHandler(opened, DropDownEnums.year)
                      }
                      labelClassName="label-default"
                      dropdownArrowColor="#2B323B"
                    />
                  </div>
                  <div>
                    <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.MONTH)}</p>
                    <ChartDropdown
                      onChange={handleDropMonthChange}
                      selectedDropDownItem={selectedMonth}
                      dropDownItems={validMonthOptions}
                      dropDownOpen={isDropDownOpen === DropDownEnums.month}
                      setDropDownOpen={
                        (opened: boolean) => dropDownOpenCloseHandler(opened, DropDownEnums.month)
                      }
                      labelClassName="label-default"
                      dropdownArrowColor="#2B323B"
                    />
                  </div>
                </div>
              </>
            )}
            {!sentViaEmail ? (
              <div className="downloadMoal__button_container">
                {downloadError ? (
                  <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadDAExcel} />
                ) : (
                  <BasicButton disabled={showProgressBar || !locations?.length} text={t(TRANSLATIONS.DOWNLOAD)} className={`${showProgressBar || !locations?.length ? 'downloadMoal__button_container__download_button_disabled_wrap' : 'downloadMoal__button_container__download_button_wrap'}`} onClick={downloadDAExcel} />
                )}
                <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button_wrap" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
              </div>
            ) : (
              <div className="downloadMoal__continue_button_container">
                <BasicButton text={t(TRANSLATIONS.CONTINUE)} className="downloadMoal__continue_button_container__continue_button" onClick={closeModal} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DailyAgenda;
